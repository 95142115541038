import type { ReactNode } from "react";
import React, { useState, useEffect } from "react";

import { updateUserMeta } from "@actions";
import {
  Button,
  ButtonGroup,
  SearchText,
  Select,
  StatusPill,
  SubTextCell,
  Table,
  TableActions,
  TableLink,
  ToolTip,
  ActionModal,
  DetailSlideOut,
  DocumentationButton,
  IconTrueFalse,
} from "@commonComponents";
import Markdown from "markdown-to-jsx";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { styled } from "styled-components";

import { colorTheme, UserRecordColumns, gridSpacing } from "@utils";

import ReorderModal from "./ReorderModal";
import { checkGroupPermission, GroupBy } from "../helpers";
import GroupedObjects from "../PortalHome/GroupedObjects";
import FilterButton from "~/src/CommonComponents/AdvancedFilters/FilterButton";
import type { ListAnalyticDomain, ListInsightsDashboard } from "~/src/types";
import { LEAP_INSIGHTS_URLS } from "~/src/urls";
import {
  includesIgnoreCase,
  internalPageSuccess,
  readablePhoneNumber,
} from "~/src/utils/tsUtils";

const Container = styled.div`
  width: 100%;
  overflow-x: auto;
  padding: ${gridSpacing[4]}px;
`;

const Header = styled.h2`
  padding-top: ${gridSpacing[3]}px;
  padding-bottom: ${gridSpacing[3]}px;
  margin-right: ${gridSpacing[3]}px;
`;

const ContentWrapper = styled.div`
  height: calc(
    100vh - 265px
  ); // 265px is the height of the top bar + header + tabs
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

type FilterProp = {
  search: string;
  analytic_domain: string | null;
  subdomain: string[];
};

interface SlideoutItem {
  title: string;
  content?: number | string | ReactNode;
}
type SlideoutData = SlideoutItem[][];

type DashboardSettingsProps = {
  insightsDashboards: ListInsightsDashboard[];
  getInsightsDashboards: () => void;
  subjects: ListAnalyticDomain[];
  updateUserMeta: (key: string, value: string | string[]) => void;
  leapInsightsDashboardSettingGroupBy: string;
  dashboardSettingsSubjectsClosed: string[];
  dashboardSettingsSubdomainsClosed: string[];
};

const DashboardSettings = ({
  insightsDashboards,
  getInsightsDashboards,
  subjects,
  updateUserMeta,
  leapInsightsDashboardSettingGroupBy,
  dashboardSettingsSubjectsClosed,
  dashboardSettingsSubdomainsClosed,
}: DashboardSettingsProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [actionModalIsOpened, setActionModalIsOpened] =
    useState<boolean>(false);
  const [selectedDashboard, setSelectedDashboard] =
    useState<ListInsightsDashboard | null>(null);
  const [dashboardSlideoutOpen, setDashboardSlideoutOpen] =
    useState<boolean>(false);
  const [slideoutData, setSlideoutData] = useState<SlideoutData>([]);
  const [filters, setFilters] = useState<FilterProp>({
    search: "",
    analytic_domain: null,
    subdomain: [],
  });
  const [reorderModalIsOpened, setReorderModalIsOpened] =
    useState<boolean>(false);
  const [groupBy, setGroupBy] = useState<string>(
    leapInsightsDashboardSettingGroupBy ?? GroupBy.analyticDomain,
  );
  const [subjectsClosed, setSubjectsClosed] = useState<string[]>(
    dashboardSettingsSubjectsClosed ?? [],
  );
  const [subdomainsClosed, setSubDomainsClosed] = useState<string[]>(
    dashboardSettingsSubdomainsClosed ?? [],
  );

  const filteredSubjects = subjects.filter(
    (subject) =>
      (!filters.analytic_domain || subject.name === filters.analytic_domain) &&
      (filters.subdomain.length === 0 ||
        subject.subdomains.some((subdomain) =>
          filters.subdomain.includes(subdomain.name),
        )) &&
      (!filters.search ||
        insightsDashboards.some(
          (d) =>
            includesIgnoreCase(d.name, filters.search) &&
            d.subdomain.analytic_domain.uuid === subject.uuid,
        )),
  );

  const filteredCustomerDashboards = insightsDashboards.filter((dashboard) => {
    const subjectAreaName = dashboard.subdomain.analytic_domain.name;
    const subjectGroupName = dashboard.subdomain.name;

    return (
      dashboard.dashboard_type === "customer" &&
      (includesIgnoreCase(dashboard.name, filters.search) ||
        includesIgnoreCase(
          dashboard.embedded_details?.original_name,
          filters.search,
        )) &&
      (!filters.analytic_domain ||
        subjectAreaName === filters.analytic_domain) &&
      (filters.subdomain.length === 0 ||
        filters.subdomain.includes(subjectGroupName))
    );
  });

  // When the page is rerouted from add/edit dashboard, fetch the managed dashboards
  useEffect(() => {
    if (
      location.pathname === `${LEAP_INSIGHTS_URLS.baseUrl}/dashboard-settings`
    ) {
      getInsightsDashboards();
    }
  }, [location]);

  const showDashboardDetails = (obj: ListInsightsDashboard) => {
    // Determine rank within context of other customer dashboards in same subdomain
    const lucernaOffset = insightsDashboards.filter(
      (dashboard) =>
        dashboard.subdomain.uuid === obj.subdomain.uuid &&
        dashboard.dashboard_type === "lucerna",
    ).length;
    const rank = obj.rank - lucernaOffset;

    setSlideoutData([
      [
        {
          title: "Analytic Domain",
          content: obj.subdomain.analytic_domain.name,
        },
        {
          title: "Subdomain",
          content: obj.subdomain.name,
        },
        {
          title: "Dashboard Name",
          content: obj.name,
        },
        {
          title: "Original Name",
          content: obj.embedded_details?.original_name,
        },
      ],
      [
        {
          title: "Dashboard UUID",
          content: obj.embedded_details?.dashboard_uuid,
        },
        {
          title: "Rank",
          content: rank,
        },
        {
          title: "Contains PHI",
          content: (
            <IconTrueFalse value={obj.embedded_details?.contains_phi || null} />
          ),
        },
        {
          title: "Lucerna Managed",
          content: (
            <IconTrueFalse
              value={obj.embedded_details?.lucerna_managed || null}
            />
          ),
        },
      ],
      [
        {
          title: "Short Description",
          content: obj.short_description,
        },
        {
          title: "Description",
          content: obj.description,
        },
        {
          title: "Description Preview",
          content: obj.description ? (
            <Markdown>{obj.description}</Markdown>
          ) : undefined,
        },
      ],
      [
        {
          title: "Information",
          content: obj.embedded_details?.information,
        },
        {
          title: "Information Preview",
          content: obj.embedded_details?.information ? (
            <Markdown>{obj.embedded_details?.information}</Markdown>
          ) : undefined,
        },
      ],
      [
        {
          title: "Support Contact Name",
          content: obj.embedded_details?.support_contact?.name,
        },
        {
          title: "Support Contact Title",
          content: obj.embedded_details?.support_contact?.title,
        },
        {
          title: "Support Contact Email",
          content: obj.embedded_details?.support_contact?.email,
        },
        {
          title: "Support Contact Phone",
          content:
            readablePhoneNumber(obj.embedded_details?.support_contact?.phone) ??
            "",
        },
      ],
      [
        {
          title: "Shared Folder Permissions",
          content: (
            <Table
              hidePagination
              data={
                Array.isArray(obj.embedded_details?.shared_folder_permissions)
                  ? obj.embedded_details?.shared_folder_permissions.map(
                      (permission: string) => {
                        return {
                          value: permission.split("/").at(-1),
                        };
                      },
                    )
                  : []
              }
              columns={[{ Header: "Permissions", accessor: "value" }]}
            />
          ),
        },
      ],
      [
        {
          title: "Documentation Links",
          content: (
            <Table
              hidePagination
              data={obj.embedded_details?.documentation_links || []}
              columns={[
                { Header: "Name", accessor: "name" },
                {
                  Header: "URL",
                  accessor: "url",
                  Cell: (props: { value: string }) => (
                    <TableLink newTab url={props.value}>
                      {props.value}
                    </TableLink>
                  ),
                },
              ]}
            />
          ),
        },
      ],
    ]);
    setDashboardSlideoutOpen(true);
  };

  const getColumns = () => {
    type DashboardColumnProps = {
      value: string;
      row: { original: ListInsightsDashboard };
    };

    const columns = [
      {
        Header: "Dashboard Name",
        accessor: "name",
        Cell: (props: DashboardColumnProps) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <TableLink
              newTab
              url={`${LEAP_INSIGHTS_URLS.baseUrl}/${props.row.original.path}`}
            >
              {props.value}
            </TableLink>
            {props.row.original.embedded_details?.lucerna_managed && (
              <ToolTip
                text="Lucerna Managed"
                style={{ marginLeft: gridSpacing[2] }}
              >
                <i
                  className="fa-solid fa-screwdriver-wrench"
                  style={{ color: colorTheme("neutralL1") }}
                />
              </ToolTip>
            )}
          </div>
        ),
      },
    ];

    if (groupBy === "ungroup") {
      columns.push({
        Header: "Analytic Domain",
        accessor: "subdomain.analytic_domain.name",
        // @ts-expect-error ignoring for now since this does not come from ts
        Cell: (props: DashboardColumnProps) =>
          `${props.value} /
            ${props.row.original.subdomain.name}
          `,
      });
    } else if (groupBy === GroupBy.analyticDomain) {
      // @ts-expect-error ignoring for now since this does not come from ts
      columns.push({
        Header: "Subdomain",
        accessor: "subdomain.name",
      });
    }

    columns.push(
      ...[
        {
          Header: "Rank",
          accessor: "rank",
          Cell: (props: DashboardColumnProps) => {
            // Only show rank within context of other customer dashboards in same subdomain
            const totalCount = insightsDashboards.filter(
              (dashboard) =>
                dashboard.subdomain.uuid ===
                  props.row.original.subdomain.uuid &&
                dashboard.dashboard_type === "customer",
            ).length;
            const lucernaOffset = insightsDashboards.filter(
              (dashboard) =>
                dashboard.subdomain.uuid ===
                  props.row.original.subdomain.uuid &&
                dashboard.dashboard_type === "lucerna",
            ).length;
            const rank = Number(props.value) - lucernaOffset;
            return <StatusPill type="default" text={`${rank}/${totalCount}`} />;
          },
          // @ts-expect-error ignoring for now since this does not come from ts
          width: 50,
        },
        {
          Header: "Original Name",
          id: "dashboard_uuid",
          accessor: "dashboard_uuid",
          Cell: (props: DashboardColumnProps) => (
            <SubTextCell
              content={props.row.original.embedded_details?.original_name}
              subText={props.value}
            />
          ),
        },
        ...UserRecordColumns,
        {
          Header: "Actions",
          accessor: "uuid",
          width: 85,
          disableResizing: true,
          overflow: true,
          Cell: (props: DashboardColumnProps) => (
            <TableActions
              direction="left"
              items={[
                {
                  title: "View Details",
                  icon: "fa-eye",
                  onClick: () => {
                    showDashboardDetails(props.row.original);
                  },
                },
                {
                  title: "Edit",
                  icon: "fa-pencil",
                  onClick: () =>
                    navigate(
                      `${LEAP_INSIGHTS_URLS.baseUrl}/dashboard-settings/edit/${props.value}`,
                    ),
                },
                {
                  title: "Reorder",
                  icon: "fa-sort",
                  onClick: () => {
                    setSelectedDashboard(props.row.original);
                    setReorderModalIsOpened(true);
                  },
                },
                {
                  title: "Delete",
                  icon: "fa-trash",
                  onClick: () => {
                    setSelectedDashboard(props.row.original);
                    setActionModalIsOpened(true);
                  },
                },
              ]}
            />
          ),
        },
      ],
    );
    return columns;
  };

  const getSubdomainOptions = (filters: FilterProp) => {
    const foundSubject = subjects.find(
      (subject) => subject.name === filters.analytic_domain,
    );

    if (foundSubject) {
      return foundSubject.subdomains
        .filter((subdomain) =>
          checkGroupPermission(foundSubject.path || "", subdomain.path),
        )
        .map((group) => {
          return {
            label: group.name,
            value: group.name,
          };
        });
    }

    return subjects.flatMap((subject) =>
      subject.subdomains
        .filter((subdomain) =>
          checkGroupPermission(subject.path, subdomain.path),
        )
        .map((subdomain) => {
          return {
            label: subdomain.name,
            value: subdomain.name,
          };
        }),
    );
  };

  const renderContent = () => {
    if (groupBy === "ungroup") {
      return (
        <Table
          hidePagination
          columns={getColumns()}
          data={filteredCustomerDashboards}
          defaultSorted={[
            {
              id: "name",
            },
          ]}
        />
      );
    } else if (groupBy === GroupBy.analyticDomain) {
      return filteredSubjects.map((subject) => {
        const subdomains = subject.subdomains.filter((subdomain) =>
          checkGroupPermission(subject.path, subdomain.path),
        );

        const subjectDashboards = filteredCustomerDashboards.filter(
          (dashboard) =>
            subdomains.some(
              (subdomain) => subdomain.uuid === dashboard.subdomain.uuid,
            ),
        );
        const isCollapsed = subjectsClosed.includes(subject.uuid);

        const toggleGroup = () => {
          if (isCollapsed) {
            const _subjects = subjectsClosed.filter((g) => g !== subject.uuid);
            setSubjectsClosed(_subjects);
            updateUserMeta("dashboardSettingsSubjectsClosed", _subjects);
          } else {
            const _subjects = [...subjectsClosed, subject.uuid];
            setSubjectsClosed(_subjects);
            updateUserMeta("dashboardSettingsSubjectsClosed", _subjects);
          }
        };

        return (
          <div style={{ marginTop: gridSpacing[3] }} key={subject.uuid}>
            <GroupedObjects
              isCollapsed={isCollapsed}
              toggleGroup={toggleGroup}
              icon={subject.icon}
              title={subject.name}
              description={subject.short_description}
              childrenCount={subjectDashboards.length}
              content={
                <Table
                  hidePagination
                  columns={getColumns()}
                  data={subjectDashboards}
                  defaultSorted={[
                    {
                      id: "rank",
                    },
                  ]}
                />
              }
            />
          </div>
        );
      });
    } else {
      const subdomains = subjects.flatMap((subject) =>
        subject.subdomains.map((subdomain) => {
          return {
            ...subdomain,
            analytic_domain: subject,
          };
        }),
      );

      const filteredSubdomains = subdomains.filter((subdomain) => {
        const hasSearchFilter =
          !filters.search ||
          insightsDashboards.some(
            (d) =>
              includesIgnoreCase(d.name, filters.search) &&
              d.subdomain.uuid === subdomain.uuid,
          );

        const hasAnalyticDomainFilter =
          !filters.analytic_domain ||
          subdomain.analytic_domain.name === filters.analytic_domain;

        const hasSubdomainFilter =
          filters.subdomain.length === 0 ||
          filters.subdomain.includes(subdomain.name);

        return hasSearchFilter && hasAnalyticDomainFilter && hasSubdomainFilter;
      });

      return filteredSubdomains.map((subdomain) => {
        const isCollapsed = subdomainsClosed.includes(subdomain.uuid);
        const toggleGroup = () => {
          if (isCollapsed) {
            const _subdomains = subdomainsClosed.filter(
              (g) => g !== subdomain.uuid,
            );
            setSubDomainsClosed(_subdomains);
            updateUserMeta("dashboardSettingsSubdomainsClosed", _subdomains);
          } else {
            const _subdomains = [...subdomainsClosed, subdomain.uuid];
            setSubDomainsClosed(_subdomains);
            updateUserMeta("dashboardSettingsSubdomainsClosed", _subdomains);
          }
        };

        const subdomainDashboards = filteredCustomerDashboards.filter(
          (d) => d.subdomain.uuid === subdomain.uuid,
        );

        return (
          <div style={{ marginTop: gridSpacing[3] }} key={subdomain.uuid}>
            <GroupedObjects
              isCollapsed={isCollapsed}
              toggleGroup={toggleGroup}
              icon={subdomain.analytic_domain.icon}
              title={`${subdomain.analytic_domain.name} / ${subdomain.name}`}
              childrenCount={subdomainDashboards.length}
              description={subdomain.short_description}
              content={
                <Table
                  hidePagination
                  columns={getColumns()}
                  data={subdomainDashboards}
                  defaultSorted={[
                    {
                      id: "rank",
                    },
                  ]}
                />
              }
            />
          </div>
        );
      });
    }
  };

  const handleCollapseAll = () => {
    if (groupBy === GroupBy.analyticDomain) {
      const _subjects = subjects.map((subject) => subject.uuid);
      setSubjectsClosed(_subjects);
      updateUserMeta("dashboardSettingsSubjectsClosed", _subjects);
    } else {
      const _subdomains = subjects.flatMap((subject) =>
        subject.subdomains.map((subdomain) => subdomain.uuid),
      );
      setSubDomainsClosed(_subdomains);
      updateUserMeta("dashboardSettingsSubdomainsClosed", _subdomains);
    }
  };

  const handleExpandAll = () => {
    if (groupBy === GroupBy.analyticDomain) {
      setSubjectsClosed([]);
      updateUserMeta("dashboardSettingsSubjectsClosed", []);
    } else {
      setSubDomainsClosed([]);
      updateUserMeta("dashboardSettingsSubdomainsClosed", []);
    }
  };

  return (
    <>
      <DetailSlideOut
        isOpened={dashboardSlideoutOpen}
        setIsOpened={setDashboardSlideoutOpen}
        closeCallback={() => setSlideoutData([])}
        headerText="Dashboard Details"
        data={slideoutData}
        size={2}
      />
      <ActionModal
        isOpened={actionModalIsOpened}
        setIsOpened={setActionModalIsOpened}
        title="Delete Dashboard Embedding"
        message="Are you sure you want to delete this dashboard embedding?"
        method="delete"
        url={`insights/dashboards/${selectedDashboard?.uuid}`}
        successCallback={() => {
          getInsightsDashboards();
          internalPageSuccess("Dashboard deleted successfully");
        }}
      />
      <ReorderModal
        isOpened={reorderModalIsOpened}
        setIsOpened={setReorderModalIsOpened}
        selectedDashboard={selectedDashboard}
        setSelectedDashboard={setSelectedDashboard}
        dashboards={insightsDashboards.filter(
          (dashboard) =>
            dashboard.dashboard_type === "customer" &&
            dashboard.subdomain.uuid === selectedDashboard?.subdomain.uuid,
        )}
        fetchManagedDashboards={getInsightsDashboards}
      />
      <Container>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Header>Dashboard Settings</Header>
            <DocumentationButton
              docRoute={`${LEAP_INSIGHTS_URLS.baseUrl}/dashboard-settings`}
            />
          </div>
          <Button
            text="Add Dashboard"
            onClick={() =>
              navigate(`${LEAP_INSIGHTS_URLS.baseUrl}/dashboard-settings/add`)
            }
          />
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: gridSpacing[3],
            }}
          >
            <SearchText
              fixedWidth
              value={filters.search}
              onChange={(e) =>
                setFilters({ ...filters, search: e.target.value })
              }
            />
            <ButtonGroup
              small
              value={groupBy}
              onChange={(value) => {
                setGroupBy(value as string);
              }}
            >
              <Button
                text="Ungroup"
                onClick={() => {
                  setGroupBy(GroupBy.ungroup);
                  updateUserMeta(
                    "leapInsightsDashboardSettingGroupBy",
                    GroupBy.ungroup,
                  );
                }}
                tooltip="Ungroup"
                value={GroupBy.ungroup}
              />
              <Button
                text="Domain"
                onClick={() => {
                  setGroupBy(GroupBy.analyticDomain);
                  updateUserMeta(
                    "leapInsightsDashboardSettingGroupBy",
                    GroupBy.analyticDomain,
                  );
                }}
                tooltip="Group by domain"
                value={GroupBy.analyticDomain}
              />
              <Button
                text="Subdomain"
                onClick={() => {
                  setGroupBy(GroupBy.subdomain);
                  updateUserMeta(
                    "leapInsightsDashboardSettingGroupBy",
                    GroupBy.subdomain,
                  );
                }}
                tooltip="Group by subdomain"
                value={GroupBy.subdomain}
              />
            </ButtonGroup>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", minHeight: 34 }}
            >
              <Select
                value={filters.analytic_domain}
                options={subjects
                  .filter(
                    (subject) =>
                      subject.subdomains.filter((subdomain) =>
                        checkGroupPermission(subject.path, subdomain.path),
                      ).length > 0,
                  )
                  .map((subject) => {
                    return {
                      label: subject.name,
                      value: subject.name,
                    };
                  })}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    analytic_domain: e,
                    subdomain: [],
                  });
                }}
                customComponent={
                  <FilterButton
                    label="Analytic Domain"
                    values={
                      filters.analytic_domain
                        ? [filters.analytic_domain]
                        : undefined
                    }
                    clearValues={() =>
                      setFilters({ ...filters, analytic_domain: "" })
                    }
                  />
                }
              />
              <Select
                isMulti
                ml={2}
                value={filters.subdomain}
                options={getSubdomainOptions(filters)}
                onChange={(e) => setFilters({ ...filters, subdomain: e })}
                customComponent={
                  <FilterButton
                    label="Subdomain"
                    values={filters.subdomain}
                    clearValues={() =>
                      setFilters({ ...filters, subdomain: [] })
                    }
                  />
                }
              />
            </div>
            {groupBy !== "ungroup" && (
              <ButtonGroup small>
                <Button
                  icon="fa-maximize"
                  onClick={handleExpandAll}
                  tooltip="Expand All"
                  value="expand"
                />
                <Button
                  icon="fa-minimize"
                  onClick={handleCollapseAll}
                  tooltip="Collapse All"
                  value="collapse"
                />
              </ButtonGroup>
            )}
          </div>
          <ContentWrapper>{renderContent()}</ContentWrapper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state: {
  main: {
    user: {
      metadata: {
        leapInsightsDashboardSettingGroupBy: string;
        dashboardSettingsSubjectsClosed: string[];
        dashboardSettingsSubdomainsClosed: string[];
      };
    };
  };
}) => {
  return {
    leapInsightsDashboardSettingGroupBy:
      state.main.user.metadata?.leapInsightsDashboardSettingGroupBy,
    dashboardSettingsSubjectsClosed:
      state.main.user.metadata?.dashboardSettingsSubjectsClosed,
    dashboardSettingsSubdomainsClosed:
      state.main.user.metadata?.dashboardSettingsSubdomainsClosed,
  };
};

export default connect(mapStateToProps, {
  updateUserMeta,
})(DashboardSettings);
