import React from "react";

import { styled, css } from "styled-components";

import { colorTheme } from "@utils";

interface TableIconContainerProps {
  $direction?: string;
  $clickable?: boolean;
}

const TableIconContainer = styled.div<TableIconContainerProps>`
  ${({ $direction }) =>
    $direction === "center" &&
    css`
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      align-items: center;
    `}

  ${({ $clickable }) =>
    $clickable &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;

interface IconTrueFalseProps {
  value?: boolean | string | null;
  style?: React.CSSProperties;
  direction?: string;
  onClick?: () => void;
}

const IconTrueFalse: React.FC<IconTrueFalseProps> = ({
  value,
  style,
  direction,
  onClick,
}) => {
  const renderIcon = () => {
    if (value === true) {
      return (
        <i
          className="fa-solid fa-check"
          style={{ color: colorTheme("success") }}
        />
      );
    } else if (value === false) {
      return (
        <i
          className="fa-solid fa-xmark"
          style={{ color: colorTheme("danger") }}
        />
      );
    }
    return "-";
  };

  return (
    <TableIconContainer
      $direction={direction}
      style={style}
      $clickable={!!onClick}
      onClick={onClick}
    >
      {renderIcon()}
    </TableIconContainer>
  );
};

export default IconTrueFalse;
