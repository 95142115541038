/* eslint-disable max-len */
import type { CSSProperties } from "react";
import React from "react";

import styled from "styled-components";

interface LoadingProps {
  /**
   * Determines if the loader should be centered horizontally.
   */
  center?: boolean;

  /**
   * Padding value for the loader.
   */
  padding?: number;

  /**
   * Additional styles to apply to the loader.
   */
  style?: CSSProperties;

  /**
   * Color theme object.
   */
  colorTheme?: object;

  /**
   * Test ID used for testing purposes.
   */
  testId?: string;
}

const LoaderWrapper = styled.div<{ $center: boolean; $padding: number }>`
  display: ${({ $center }) => ($center ? "flex" : "block")};
  flex-direction: ${({ $center }) => ($center ? "row" : "initial")};
  justify-content: ${({ $center }) => ($center ? "center" : "initial")};
  padding: ${({ $padding }) => $padding + "px"};
  visibility: hidden;
  animation: 0.75s fadeIn;
  animation-fill-mode: forwards;

  @keyframes fadeIn {
    99% {
      visibility: hidden;
    }
    100% {
      visibility: visible;
    }
  }

  @keyframes mainHaloColor {
    0% {
      fill: #b6b6b6;
    }
    50% {
      fill: #ffb81c;
    }
    100% {
      fill: #b6b6b6;
    }
  }

  @keyframes cornerColor {
    0% {
      fill: #737373;
    }
    50% {
      fill: #103d69;
    }
    100% {
      fill: #737373;
    }
  }

  @keyframes circleColor {
    0% {
      fill: #999999;
    }
    50% {
      fill: #4698cb;
    }
    100% {
      fill: #999999;
    }
  }

  @keyframes haloColor {
    0% {
      fill: #999999;
    }
    50% {
      fill: #ffcd5f;
    }
    100% {
      fill: #999999;
    }
  }

  @keyframes sizeAnimation {
    0% {
      transform: scale(0.7);
    }
    50% {
      transform: scale(0.75);
    }
    100% {
      transform: scale(0.7);
    }
  }

  #mainHalo {
    animation: mainHaloColor 3s infinite;
  }

  #corner {
    animation: cornerColor 3s infinite;
  }

  #circle {
    animation: circleColor 3s infinite;
  }

  #halo {
    animation: haloColor 3s infinite;
  }

  #loadingLogo {
    animation: sizeAnimation 3s infinite;
  }

  #mainHalo,
  #corner,
  #circle,
  #halo,
  #loadingLogo {
    transition: all 1s ease;
    animation-delay: 0.75s;
  }
`;

const Loading: React.FC<LoadingProps> = ({
  center = false,
  padding = 0,
  style = {},
  testId,
}) => (
  <LoaderWrapper
    $center={center}
    $padding={padding}
    style={style}
    data-testid={testId || "loading"}
  >
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="loadingLogo"
    >
      <path
        d="M1.92002 36.24C1.03002 36.2 0.320021 35.45 0.390021 34.56C0.790021 29.4 2.61002 24.47 5.66002 20.28C9.03002 15.66 13.75 12.15 19.22 10.2C24.7 8.25 30.68 7.95 36.42 9.33C42.16 10.71 47.39 13.71 51.46 17.96C55.53 22.21 58.25 27.5 59.27 33.18C60.3 38.86 59.58 44.67 57.22 49.87C54.86 55.07 50.95 59.43 46 62.39C41.5 65.08 36.32 66.52 31 66.56C30.1 66.56 29.4 65.81 29.42 64.92L29.57 57.67C29.59 56.74 30.37 56.02 31.3 55.98C34.45 55.85 37.5 54.95 40.18 53.34C43.33 51.45 45.82 48.68 47.32 45.37C48.82 42.06 49.28 38.37 48.62 34.75C47.96 31.13 46.24 27.77 43.65 25.06C41.06 22.36 37.73 20.45 34.08 19.57C30.43 18.69 26.62 18.88 23.14 20.12C19.66 21.36 16.65 23.59 14.51 26.53C12.7 29.02 11.57 31.91 11.22 34.96C11.11 35.88 10.34 36.61 9.42002 36.57L1.91002 36.23L1.92002 36.24Z"
        id="mainHalo"
      />
      <path
        d="M30.19 47.98C35.8841 47.98 40.5 43.3641 40.5 37.67C40.5 31.9759 35.8841 27.36 30.19 27.36C24.4959 27.36 19.88 31.9759 19.88 37.67C19.88 43.3641 24.4959 47.98 30.19 47.98Z"
        id="circle"
      />
      <path
        d="M0.960003 64.87L0.410003 39.67C0.390003 38.74 1.13 37.98 2.06 37.98H9.58C10.45 37.98 11.18 38.66 11.23 39.54L12.23 57.17C12.29 58.15 13.19 58.87 14.16 58.7L25.87 56.67C26.88 56.5 27.8 57.27 27.8 58.3V65.38C27.8 66.31 27.04 67.05 26.11 67.03L2.56 66.49C1.68 66.47 0.970003 65.76 0.950003 64.88L0.960003 64.87Z"
        id="corner"
      />
      <path
        d="M63.94 38.17C63.94 44.77 62.04 50.93 58.77 56.15H62.83C65.76 50.81 67.44 44.68 67.44 38.17C67.44 17.51 50.63 0.690002 29.97 0.690002C23.14 0.690002 16.73 2.54 11.21 5.75V9.86C16.59 6.28 23.04 4.19 29.97 4.19C48.7 4.19 63.94 19.43 63.94 38.17Z"
        id="halo"
      />
    </svg>
  </LoaderWrapper>
);

export default Loading;
