import React, { useState, useEffect } from "react";

import { updateUserMeta } from "@actions";
import {
  AdvancedFilters,
  Table,
  SubTextCell,
  TableLink,
  DetailSlideOut,
} from "@commonComponents";
import axios from "axios";
import type { AxiosResponse } from "axios";
import moment from "moment";
import type { ParsedQuery } from "query-string";
import queryString from "query-string";
import { connect } from "react-redux";

import { gridSpacing, makeHumanReadable } from "@utils";

import Header from "~/src/CommonComponents/DataVisualization/Common/Header";
import type { DetailData } from "~/src/CommonComponents/DetailSlideOut/types";
import { setTableExport } from "~/src/CommonComponents/TableExport/TableExport";
import type {
  UserPageViewList,
  UserPageViewRetrieve,
  MonitoringPageViewList200Response,
} from "~/src/types";
import { internalPageError } from "~/src/utils/tsUtils";

interface TableDataState {
  pageSize: number;
  currentPage: number;
  sort: { desc?: boolean; id: number | string };
}

const ActivityAnalysis = (activityAnalysisDashboardPageSize: {
  pageSize: number;
}) => {
  const [data, setData] = useState<UserPageViewList[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useState<ParsedQuery>({});
  const [tableData, setTableData] = useState<TableDataState>({
    pageSize: activityAnalysisDashboardPageSize.pageSize,
    currentPage: 0,
    sort: {
      id: "created",
      desc: true,
    },
  });
  const [totalCount, setTotalCount] = useState<number>(0);
  const [detailIsOpened, setDetailIsOpened] = useState<boolean>(false);

  const [detailData, setDetailData] = useState<DetailData>([]);

  const [detailIsLoading, setDetailIsLoading] = useState<boolean>(false);

  const getPageViewData = async (
    searchParams: ParsedQuery,
    tableData: TableDataState,
  ) => {
    const query: {
      user?: string;
      l1DashboardName?: string;
      l2DashboardName?: string;
      pageName?: string;
      objectPk?: string;
      objectIdentifier?: string;
      objectVersion?: string;
      l1App?: string;
      l2App?: string;
      created?: string;
      search?: string;
      ordering?: string | number;
      page: number;
      page_size: number;
    } = {
      user: searchParams?.user?.toString(),
      l1DashboardName: searchParams?.l1DashboardName?.toString(),
      l2DashboardName: searchParams?.l2DashboardName?.toString(),
      pageName: searchParams?.pageName?.toString(),
      objectPk: searchParams?.objectPk?.toString(),
      objectIdentifier: searchParams?.objectIdentifier?.toString(),
      objectVersion: searchParams?.objectVersion?.toString(),
      l1App: searchParams?.l1App?.toString(),
      l2App: searchParams?.l2App?.toString(),
      created: searchParams?.created?.toString(),
      search: searchParams?.search?.toString(),
      ordering: searchParams?.ordering?.toString(),
      page: tableData.currentPage + 1,
      page_size: tableData.pageSize,
    };

    if (tableData.sort) {
      if (tableData.sort.desc) {
        query.ordering = `-${tableData.sort.id}`;
      } else {
        query.ordering = tableData.sort.id;
      }
    }

    const res = (await axios
      .get(`monitoring/page_view`, {
        params: query,
      })
      .catch((err) => {
        console.error(err);
      })) as AxiosResponse<MonitoringPageViewList200Response>;
    return {
      viewsData: res?.data?.result?.results,
      totalViews: res?.data?.result?.count,
    };
  };

  useEffect(() => {
    getPageViewData(searchParams, tableData)
      .then(({ viewsData, totalViews }) => {
        setData(viewsData ?? []);
        setTotalCount(totalViews ?? 0);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        internalPageError("Something went wrong loading page view data");
      });
  }, [JSON.stringify(tableData), searchParams]);

  const updateDetailInfo = (uuid: string) => {
    setDetailIsLoading(true);
    setDetailIsOpened(true);
    axios
      .get(`/monitoring/page_view/${uuid}`)
      .then((res) => {
        const rowData: UserPageViewRetrieve = res.data.result;
        setDetailData([
          [
            {
              title: "Event ID",
              content: rowData?.uuid,
            },
          ],
          [
            {
              title: "User",
              content: (
                <SubTextCell
                  content={rowData?.user.full_name}
                  subText={rowData?.user.email}
                />
              ),
            },
          ],
          [
            {
              title: "Dashboard Name",
              content: rowData?.page_name,
            },
            {
              title: "Dashboard Level 1 Name",
              content: makeHumanReadable(
                rowData?.relative_url?.split("/")?.[2],
              ),
            },
            {
              title: "Dashboard Level 2 Name",
              content: makeHumanReadable(
                rowData?.relative_url?.split("/")?.[3],
              ),
            },
          ],
          [
            {
              title: "User Group(s)",
              content: (
                <Table
                  hidePagination
                  data={
                    rowData?.user_groups?.map((g: string) => {
                      return {
                        group_name: g,
                      };
                    }) ?? []
                  }
                  columns={[
                    {
                      Header: "Group Name",
                      accessor: "group_name",
                    },
                  ]}
                />
              ),
            },
          ],
        ]);
        setDetailIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        internalPageError("Something went wrong loading page view detail");
      });
  };

  const columns = [
    {
      Header: "Session ID",
      accessor: "uuid",
      Cell: (props: {
        value: string;
        row: {
          original: {
            uuid: string;
          };
        };
      }) => (
        <TableLink
          onClick={() => {
            updateDetailInfo(props.row.original.uuid);
          }}
        >
          {props.value}
        </TableLink>
      ),
    },
    {
      Header: "User",
      accessor: "user",
      Cell: (props: { value: { full_name: string; email: string } }) => (
        <SubTextCell
          content={props?.value?.full_name}
          subText={props?.value?.email}
        />
      ),
    },
    {
      Header: "Dashboard Name",
      accessor: "page_name",
    },
    {
      Header: "Activity Time",
      accessor: "created",
      Cell: (props: { value: string }) =>
        moment(props.value).format("MM/DD/YYYY h:mm a"),
    },
  ];

  const csvDownloadColumnMap = [
    { csvColumn: "activity_id", jsColumn: "uuid" },
    { csvColumn: "user_first_name", jsColumn: "user.first_name" },
    { csvColumn: "user_last_name", jsColumn: "user.last_name" },
    { csvColumn: "user_email", jsColumn: "user.email" },
    { csvColumn: "user_status", jsColumn: "user.status" },
    { csvColumn: "dashboard_name", jsColumn: "page_name" },
    { csvColumn: "dashboard_l1_name", jsColumn: "l1_app" },
    { csvColumn: "dashboard_l2_name", jsColumn: "l2_app" },
    { csvColumn: "activity_time", jsColumn: "created" },
  ];
  return (
    <>
      <DetailSlideOut
        isOpened={detailIsOpened}
        setIsOpened={setDetailIsOpened}
        headerText="Page View Detail"
        data={detailData}
        size={2}
        loading={detailIsLoading}
        closeCallback={() => {
          setDetailData([]);
        }}
      />
      <Header
        title="User Activity Report"
        toolTip="User activity report"
        style={{ marginBottom: gridSpacing[5] }}
      />
      <AdvancedFilters
        app="monitoring"
        urlPattern="page_view"
        setQuery={(params) => {
          setTableData({ ...tableData, currentPage: 0 });
          if (params === "" && Object.keys(searchParams).length === 0) {
            return;
          }
          setSearchParams(queryString.parse(params));
        }}
      />
      <Table
        apiSidedData
        columns={columns}
        data={data}
        isLoading={loading}
        currentPage={tableData.currentPage}
        totalCount={totalCount}
        setCurrentPage={(e) => {
          setTableData({ ...tableData, currentPage: e });
        }}
        setPageSize={(e) => {
          setTableData({ ...tableData, pageSize: e, currentPage: 0 });
          updateUserMeta("activityAnalysisDashboard", e);
        }}
        defaultSorted={[
          {
            id: "created",
            desc: true,
          },
        ]}
        sortOnChange={(e) => setTableData({ ...tableData, sort: e[0] })}
        pageSize={tableData.pageSize}
        exportAction={() =>
          setTableExport({
            url: "monitoring/page_view",
            exportName: "Activity Analysis",
            tableData: {
              ...tableData,
              queryParams: searchParams,
            },
            columnMap: csvDownloadColumnMap,
          })
        }
      />
    </>
  );
};

const mapStateToProps = (state: {
  main: {
    user: {
      metadata: {
        activityAnalysisDashboard: number;
      };
    };
  };
}) => {
  return {
    pageSize: state.main.user.metadata?.activityAnalysisDashboard || 25,
  };
};

export default connect(mapStateToProps, {
  updateUserMeta,
})(ActivityAnalysis);
