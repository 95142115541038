import React, { Fragment, isValidElement, useEffect } from "react";

import styled, { css } from "styled-components";

import { colorTheme } from "@utils";

import gridSpacing from "../../utils/gridSpacing";
import GraphicCallout from "../GraphicCallout/GraphicCallout";
import { getButtonClassName } from "../utilities";
import type { MarginInterface } from "../utilities/interfaces";
import { getMargins } from "../utilities/margin";

const TabNavigation = styled.div`
  display: flex;
  flex-direction: row;
`;

const TabNavigationChild = styled.div<{
  $selected?: boolean;
  $href?: string;
  $hideNonSelectedUnderline?: boolean;
}>`
  cursor: ${({ $selected }) => ($selected ? "default" : "pointer")};
  color: ${({ $selected }) =>
    $selected ? colorTheme("primary") : colorTheme("neutralL1")};
  display: flex;
  align-items: center;
  padding-top: ${gridSpacing[4]}px;
  ${({ $href, $selected, $hideNonSelectedUnderline }) =>
    !$href &&
    css`
      margin-bottom: ${gridSpacing[6]}px;
      padding-bottom: ${$selected ? gridSpacing[4] - 1 : gridSpacing[4]}px;
      border-bottom: ${$selected
        ? `2px solid ${colorTheme("primary")}`
        : $hideNonSelectedUnderline
          ? "none"
          : `1px solid ${colorTheme("neutralL4")}`};
    `}
  font-size: 14px;

  ${({ $selected }) =>
    !$selected &&
    css`
      &:hover {
        color: ${colorTheme("neutral")};
      }
    `}
`;

const RemainderLine = styled.div<{
  $hideNonSelectedUnderline?: boolean;
}>`
  flex-grow: 1;
  ${({ $hideNonSelectedUnderline }) =>
    !$hideNonSelectedUnderline &&
    css`
      border-bottom: 1px solid ${colorTheme("neutralL4")};
    `}
  margin-bottom: ${gridSpacing[6]}px;
`;

const TabContentContainer = styled.div`
  padding: 10px;
`;

interface ContentProps {
  children?: React.ReactNode;
  tabKey: string;
  icon?: string;
  title: string;
  hasError?: boolean;
  hasPermission?: boolean;
  href?: string;
  newTab?: boolean;
  count?: number;
}

function Content({ children }: ContentProps) {
  if (!children) return <div />;
  return <div>{children}</div>;
}

interface TabProps<TData> extends MarginInterface {
  children: React.ReactNode;
  icon?: string;
  currentTab?: TData;
  onSelect?: (tabKey: TData) => void;
  style?: React.CSSProperties;
  tabStyle?: React.CSSProperties;
  tabContentStyle?: React.CSSProperties;
  includeHash?: boolean;
  loadTabFromHash?: boolean;
  hideNonSelectedUnderline?: boolean;
  testId?: string;
}

function Tabs<TData extends string>({
  children,
  currentTab,
  onSelect,
  style,
  tabStyle,
  tabContentStyle,
  includeHash,
  loadTabFromHash,
  hideNonSelectedUnderline,
  testId,
  m,
  mx,
  my,
  mt,
  mb,
  mr,
  ml,
}: TabProps<TData>) {
  const childrenArray = React.Children.toArray(children);

  useEffect(() => {
    if (loadTabFromHash) {
      const allTabKeys = childrenArray.flatMap((child) => {
        if (isValidElement<ContentProps>(child)) {
          return [child.props.tabKey];
        }
        return [];
      });
      const hash = window.location.hash;
      if (hash && allTabKeys.includes(hash.substring(1))) {
        onSelect && onSelect(hash.substring(1) as TData);
      } else if (allTabKeys?.length > 0) {
        onSelect && onSelect(allTabKeys[0] as TData);
      }
    }
  }, []);

  const renderTab = () => {
    const visibleTab = childrenArray?.find(
      (child) =>
        isValidElement<ContentProps>(child) &&
        currentTab ===
          (child as React.ReactElement<ContentProps>)?.props?.tabKey,
    ) as React.ReactElement<ContentProps>;

    if (!isValidElement(visibleTab)) return null;

    const hasPermission =
      !!visibleTab.props.hasPermission ||
      visibleTab.props.hasPermission === undefined;

    if (visibleTab && visibleTab.props.children) {
      if (hasPermission) {
        return (
          <TabContentContainer style={tabContentStyle}>
            {visibleTab.props.children}
          </TabContentContainer>
        );
      } else {
        // Only show graphic callout if the visible tab exists
        return (
          <GraphicCallout
            testId="permission-denied-callout"
            error="permissions"
          />
        );
      }
    }
  };

  style = { ...getMargins({ m, mx, my, mt, mb, mr, ml }), ...style };

  return (
    <div style={style}>
      <TabNavigation>
        {childrenArray
          .filter((child) => !!child)
          .map((_child, i) => {
            if (!isValidElement(_child)) return null;

            const child = _child as React.ReactElement<ContentProps>;
            const tab = (
              <TabNavigationChild
                $hideNonSelectedUnderline={hideNonSelectedUnderline}
                onClick={() => {
                  if (child.props.href) return;
                  onSelect && onSelect(child.props.tabKey as TData);
                  if (includeHash)
                    window.history.pushState(
                      null,
                      "",
                      `#${child.props.tabKey}`,
                    );
                }}
                $selected={currentTab === child.props.tabKey}
                style={tabStyle}
                $href={child.props.href}
                data-testid={`${testId}-tab-${child.props.tabKey}`}
              >
                <div
                  style={{
                    color: child.props.href ? colorTheme("primary") : "inherit",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {child.props.hasError && (
                    <i
                      className="fa-solid fa-circle-exclamation"
                      style={{
                        paddingRight: gridSpacing[2],
                        color: colorTheme("danger"),
                      }}
                    />
                  )}
                  {child.props.icon && (
                    <i
                      className={getButtonClassName(child.props.icon)}
                      style={{ paddingRight: gridSpacing[2] }}
                    />
                  )}
                  {child.props.title}
                  {child.props.count !== undefined && (
                    <div
                      style={{
                        padding: `2px ${gridSpacing[2]}px`,
                        backgroundColor: colorTheme("neutralL4"),
                        color: colorTheme("neutralL1"),
                        marginLeft: gridSpacing[2],
                        borderRadius: gridSpacing[3],
                        fontSize: 12,
                      }}
                    >
                      {child.props.count}
                    </div>
                  )}
                  {child.props.href && (
                    <i
                      className="fa-solid fa-arrow-up-right-from-square"
                      style={{
                        marginLeft: gridSpacing[2],
                        color: colorTheme("primary"),
                      }}
                    />
                  )}
                </div>
              </TabNavigationChild>
            );

            return (
              <Fragment key={i}>
                {child.props.href ? (
                  <a
                    style={{
                      marginBottom: gridSpacing[6],
                      ...(!hideNonSelectedUnderline && {
                        borderBottom: `1px solid ${colorTheme("neutralL4")}`,
                        borderBottomWidth: 1,
                      }),
                      display: "block",
                    }}
                    href={child.props.href}
                    target={
                      !!child.props.newTab || child.props.newTab === undefined
                        ? "_blank"
                        : undefined
                    }
                    rel="noreferrer"
                  >
                    {tab}
                  </a>
                ) : (
                  tab
                )}
                {i !== childrenArray.length - 1 && (
                  <RemainderLine
                    $hideNonSelectedUnderline={hideNonSelectedUnderline}
                    style={{ ...tabStyle, maxWidth: gridSpacing[6] }}
                  />
                )}
              </Fragment>
            );
          })}
        <RemainderLine
          $hideNonSelectedUnderline={hideNonSelectedUnderline}
          style={{ ...tabStyle }}
        />
      </TabNavigation>
      {renderTab()}
    </div>
  );
}

Tabs.Content = Content;

export default Tabs;
